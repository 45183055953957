import {
	Message
} from 'element-ui'
import matchtitle from '../components/matchtitle';
import {
	handleCreateInvitationCode,
	handleDeletInvitationCode,
	handleQueryMatchInvitationCodes,
	// handleGetSms,
	handleAddManager,
	handleDeleteManager,
	handleQueryManagersOfMatch,
	handleSaveMatchRelateSet,
	handleQueryMatchRelateSet,
	handlepcPayFee,
	handleModifyMatchState,
	handleSetMatchFaceImgUrl,
	handleSaveMatchCertStatus,
	handleClearMatchfaceImg,
	handleGenerMatchDetailQRcode

} from '@index/api/mymatchs/matchinfo';
import {
	handleMatchInfoDetails
} from '@index/api/mymatchs/publicmatch';
import common from '@index/utils/common';
import { openDialog } from '@/components/fn-dialog.js';
import AddManager from './add-manager.vue';
export default {
	name: 'matchinfo',
	components: {
		matchtitle
	},
	data() {
		return {
			isShowQRcode: false,
			operateFlag: '0',//运营权限
			matchDetailQRcodeUrl: '',
			matchInfoState: '0',
			havAuth: false,
			loginUserId: sessionStorage.getItem("loginUserId"),
			QRcode: "",
			states: ["报名中", "报名截止", "比赛中", "比赛结束"],
			rechargeForm: {},
			rechargeDialog: false,
			editYearCalcEndTimeFlag: false,
			showYearCalcEndTimeSaveBtn: false,
			yearCalcEndTimeDisabled: true,
			addManagerCodeButton: {
				name: "获取验证码",
				disabled: false
			},
			matchInfo: { operateFalg: '0' },
			delManagerDialogVisible: false,
			addManagerForm: {},
			currManagerId: '',
			addManagerDialogVisible: false,
			delInvCodeDialogVisible: false,
			invitationCodes: [],
			invitationVisible: false,
			currInvCodeId: '',
			invitationform: {
				desc1: '系统默认'
			},
			matchSetForm: {
				payAuthFeeFlag: '0',
				authFlag: '',
				certFlag: '1',
			},
			tableDataManagers: [],
			matchId: sessionStorage.getItem("currMatchId"),
			addManagerRules: {
				name: [{
					required: true,
					message: '管理员姓名不能为空!',
					trigger: 'blur'
				}],
				phone: [{
					required: true,
					message: '管理员账号不能为空!',
					trigger: 'blur'
				}],
				code: [{
					required: true,
					message: '验证码不能为空!',
					trigger: 'blur'
				}]
			},
		}
	},
	watch: {

	},
	created() {
		// window.addEventListener('storage', this.afterQRScan)
		this.queryMatchInvitationCodes(this.matchId); //查询邀请码
		this.queryManagersOfMatch(this.matchId); //查询管理员
		this.matchInfoDetails(this.matchId);
		this.queryMatchRelateSet(this.matchId);
	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
		this.$el.remove();
	},
	methods: {
		beforeAvatarUpload(file) {
			console.log("> file.size>>>" + file.size)
			const isLt2M = (file.size / 1024) <= 300;
			// var isJPG =(file.type === 'image/jpeg'||file.type==='image/png');			
			// if(!isJPG){
			// 	this.$message.error('图片只能是 JPG或者PNG 格式!');
			// 	return false;
			// }
			if (!isLt2M) {
				this.$message.error('图片大小不能超过300kb!');
				return false;
			}

			return true;
		},
		setTableStyle() {
			return {
				cellStyle: {
					fontSize: '14px',
					fontFamily: '微软雅黑'
				},
				heightCellStyle: {
					fontSize: '14px',
					fontFamily: '微软雅黑'
				}
			}
		},
		createInvitationCode() { //创建邀请码
			if (!this.invitationform.desc1) {
				this.$message({
					type: 'error',
					message: '邀请码名称或用途不能为空!'
				});
				return;
			}
			this.invitationform.matchId = this.matchId;
			handleCreateInvitationCode(
				this.invitationform
			).then(res => {
				if (res.status == 200) {
					this.$message({
						type: 'success',
						message: res.msg
					});
					this.invitationVisible = false;
					this.invitationform.desc1 = "系统默认";
					this.queryMatchInvitationCodes(this.matchId);
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					});
				}
			});
		},
		queryMatchInvitationCodes(matchId) { //查询邀请码列表
			handleQueryMatchInvitationCodes(matchId).then(res => {
				if (res.status == 200) {
					this.invitationCodes = res.data;
				} else {
					this.$message({
						type: 'error',
						message: '获取邀请码失败!'
					});
				}
			});
		},
		openDeleteInvitationCodeDialog(id) { //删除邀请码
			this.currInvCodeId = id;
			this.delInvCodeDialogVisible = true;
		},
		okDeleteInvCode() {
			handleDeletInvitationCode(this.currInvCodeId).then(res => {
				if (res.status == 200) {
					this.queryMatchInvitationCodes(this.matchId);
					this.delInvCodeDialogVisible = false;
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					});
				}
			});
		},
		addManager() { //添加管理员
			this.addManagerForm.matchId = this.matchId;
			try {
				common.requiredValidate([{
					value: this.addManagerForm.name,
					tips: "管理员姓名不能为空!"
				}, {
					value: this.addManagerForm.phone,
					tips: "管理员账号不能为空!"
				}]);
			} catch (e) {
				this.$message({
					type: 'error',
					message: e
				});
				return;
			}


			handleAddManager(this.addManagerForm).then(res => {
				if (res.status == 200) {
					this.$message({
						type: 'success',
						message: res.msg
					});
					this.addManagerDialogVisible = false;
					this.queryManagersOfMatch(this.matchId);
					this.addManagerForm = {};
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					});
				}
			});
		},
		deleteManager(id) { //添加管理员
			handleDeleteManager(id).then(res => {
				if (res.status == 200) {
					// this.queryMatchInvitationCodes(this.matchId);
					//  this.delInvCodeDialogVisible = false;
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					});
				}
			});
		},
		queryManagersOfMatch(matchId) { //添加管理员
			handleQueryManagersOfMatch(matchId).then(res => {
				if (res.status == 200) {
					this.tableDataManagers = res.data;
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					});
				}
			});
		},
		getSmsCode() { //获取登陆验证码
			let thisObj = this;
			common.getSmsCode(this.addManagerForm.phone, function (res) {
				thisObj.addManagerForm.smsId = res;
				var time = 30;
				thisObj.addManagerCodeButton = {
					name: time + "s",
					disabled: true
				};

				var timer = setInterval(function () {
					if (--time >= 0) {
						thisObj.addManagerCodeButton = {
							name: time + "s",
							disabled: true
						}
					} else {
						thisObj.addManagerCodeButton = {
							name: "获取验证码",
							disabled: false
						};
						clearInterval(timer);
					}
				}, 1000)
			});
		},
		openDeleteMagagerDialog(id) { //打开删除管理员对话框
			this.currManagerId = id;
			this.delManagerDialogVisible = true;
		},
		okDeleteManager() { //确定删除管理员
			handleDeleteManager(this.currManagerId).then(res => {
				if (res.status == 200) {
					this.$message({
						type: 'success',
						message: res.msg
					})
					this.delManagerDialogVisible = false;
					this.queryManagersOfMatch(this.matchId);
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					})
				}
			});
		},
		editMatchInfo() { //修改比赛信息
			this.$router.push({
				path: "topublicmatch",
				query: {
					matchId: this.matchId
				}
			});


		},
		matchInfoDetails(matchId) {
			handleMatchInfoDetails(matchId).then(res => {
				if (res.status == 200) {
					this.matchInfo = res.data;
					this.matchInfoState = res.data.state;
					this.operateFalg = res.data.operateFalg;
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					});
				}
			});
		},
		selectMatchAgeCountWay(value) {
			if (value == 1) {
				this.matchSetForm.yearCalcEndTime = (new Date().getFullYear()) + "-12-31";
				this.editYearCalcEndTimeFlag = true
			} else {
				this.showYearCalcEndTimeSaveBtn = false;
				this.editYearCalcEndTimeFlag = false;
			}
			this.saveMatchRelateSet()
		},
		saveMatchRelateSet() { //保存赛事相关设置信息
			this.matchSetForm.matchId = this.matchId;
			handleSaveMatchRelateSet(
				this.matchSetForm
			).then(res => {
				if (res.status == 200) {
					this.$message({
						type: 'success',
						message: res.msg
					});
					this.queryMatchRelateSet(this.matchSetForm.matchId);
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					});
				}
			});
		},
		queryMatchRelateSet(matchId) {
			handleQueryMatchRelateSet(matchId).then(res => {
				if (res.status == 200) {
					this.matchSetForm = res.data;
					var user = JSON.parse(sessionStorage.getItem('user'));
					this.havAuth = (res.data.userType == '0') || (user.id == res.data.createUserId); //是否具有修改权限
					
					//	this.havAuth =  (user.id == res.data.createUserId); //是否具有修改权限
					if (res.data.matchAgeCountWay == '1') { //编辑年度计算截止时间
						this.showYearCalcEndTimeSaveBtn = false;
						this.editYearCalcEndTimeFlag = true;
					} else {
						this.editYearCalcEndTimeFlag = false;
					}
					this.yearCalcEndTimeDisabled = true;
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					});
				}
			});
		},
		handleEditYearCalcEndTime() {
			this.editYearCalcEndTimeFlag = false;
			this.showYearCalcEndTimeSaveBtn = true;
			this.yearCalcEndTimeDisabled = false;
		},
		openRechargeDialog() { //打开充值窗口
			this.rechargeDialog = true
		},
		closePayDialogEvent() {			
			this.queryMatchRelateSet(this.matchId);
		},
		generateQRcode() {
			if (!this.rechargeForm.fee || this.rechargeForm.fee <= 0) {
				Message({
					type: 'error',
					message: '请输入金额',
					duration: 5000
				});
				return;
			}
			handlepcPayFee({
				fee: this.rechargeForm.fee,
				bussId: this.matchSetForm.id,
				type: '2',
				description: '比赛认证预付款',
			}).then(res => {
				let bytes = new Uint8Array(res);
				let storeData = "";
				let len = bytes.byteLength;
				for (let i = 0; i < len; i++) {
					storeData += String.fromCharCode(bytes[i]);
				}
				this.QRcode = "data:image/png;base64," + window.btoa(storeData);
			})
		},
		checkAuth() { //检查用户权限
			//var user=JSON.parse(sessionStorage.getItem('user'));
		}
		, closeMatchVisible() {
			this.$refs.setMatchState.showPopper = false;
		},
		modifyMatchState() {//修改赛事状态
			handleModifyMatchState({
				matchId: this.matchInfo.id,
				state: this.matchInfoState
			}).then(res => {
				if (res.status == 200) {
					this.$message({
						type: 'success',
						message: res.msg
					});
					this.$refs.setMatchState.showPopper = false;
					this.matchInfoDetails(this.matchInfo.id);
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					});
				}
			})
		},
		uploadSuccess(res) {
			//console.log("json>>>>>" + JSON.stringify(res));

			handleSetMatchFaceImgUrl({ matchId: this.matchInfo.id, faceImgUrl: res }).then(res => {
				if (res.status == 200) {
					this.$message({
						type: 'success',
						message: res.msg
					});
					this.matchInfoDetails(this.matchInfo.id);
					this.queryMatchRelateSet(this.matchId);
					this.$refs.uploadref.clearFiles();
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					});
				}
			})
		},
		handleSaveMatchCertStatusEvent() {
			handleSaveMatchCertStatus({ matchId: this.matchInfo.id }).then(res => {
				if (res.status == 200) {
					this.$message({
						type: 'success',
						message: res.msg
					});
					this.matchInfoDetails(this.matchInfo.id);
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					});
				}
			})
		},
		openCertSetPage() {
			this.$router.push({
				path: '/certconfig',
				query: {
					menusShow: true
				}
			})
		},
		viewRegulationEvent() {
			console.log("22222222222222222>>>>>>>")
			// sessionStorage.setItem('joinMatchId',sessionStorage.getItem("currMatchId"));
			// this.$router.push({
			// 	path: '/joinmatchinfo'
			// })
			this.$message({
				type: 'error',
				message: '开发中...'
			});
		},
		deleteFaceImgEvent() {
			handleClearMatchfaceImg({ matchId: this.matchInfo.id }).then(res => {
				if (res.status == 200) {
					this.$message({
						type: 'success',
						message: res.msg
					});
					this.matchInfoDetails(this.matchInfo.id);
					this.queryMatchRelateSet(this.matchId);
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					});
				}
			});
		},
		generMatchDetailQRcode() {
			// if (this.isShowQRcode) {//关闭
			// 	this.isShowQRcode = false;
			// 	return;
			// }else{
			// 	this.isShowQRcode = true;
			// }
			handleGenerMatchDetailQRcode({ matchId: this.matchInfo.id }).then(res => {				
				this.matchDetailQRcodeUrl = res.data;
			})
		}
		,
		enterOperateIndex() {

			sessionStorage.setItem("currMatchId", this.matchInfo.id);
			sessionStorage.setItem("currMatchName", this.matchInfo.matchName);
			let routeData = this.$router.resolve({
				path: '/center/home'
			});
			window.open(routeData.href, '_blank');
		},
		addMatchManager(id) {			
			openDialog(AddManager, {
				title: id?"编辑管理员":"新增管理员",params:{id:id}, confirmText: "确定授权", callback: (res) => {
					this.queryManagersOfMatch(this.matchId); //查询管理员
				}
			})
		}

	}
}