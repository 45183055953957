
import { Dialog, Button } from "element-ui";
import Vue from "vue";

export function openDialog(view, options = { title: "温馨提示", width: "auto", isFooter: true, confirmText: "确定", cancelText: "取消" }) {
  const opt = Object.assign({ title: "温馨提示", width: "auto", isFooter: true, confirmText: "确定", cancelText: "取消", callback: new Function(),params:null }, options)
  const component = Vue.extend({
    data() {
      return {
        visible: true
      }
    },
    render(createElement) {
      return createElement(
        Dialog,
        {
          class: "dialog-box",
          props: {
            visible: this.visible,
            title: opt.title,
            width: opt.width,
            'close-on-click-modal': false,
            'destroy-on-close': true,
          },
          on: {
            close: this.handleClose,
          },
          ref: 'mydialog',
        },
        [
          createElement(view, {
            ref: 'dialogBody',
            attrs: {
                params: opt.params
            },
          }),
          opt.isFooter ? createElement('div', { slot: 'footer' }, [
            createElement(Button, {
              props: {
                type: 'default',
                size: 'small'
              },
              on: {
                click: this.handleClose
              }
            }, opt.cancelText),
            createElement(Button, {
              props: {
                type: 'primary',
                size: 'small'
              },
              on: {
                click: this.handleConfirm
              }
            }, opt.confirmText)
          ]) : ''
        ]
      )
    },
    methods: {
      handleConfirm() {
        if (this.$refs.dialogBody) {
          this.$refs.dialogBody.confirm(opt.callback, this.handleClose)
        } else {
          this.handleClose();
        }
      },
      handleClose() {
        this.visible = false;
        this.$refs.mydialog.close();
      },
      closeDialog() {
        this.handleClose();
      }
    },
    watch: {
      visible(val) {
        if (!val) {
          this.$destroy();
          this.$el.remove();
        }
      }
    },
    mounted(){
        this.$nextTick(() => {
            this.$el.querySelector('.el-dialog__body').style.padding = "0px";
        })
    },
  });
    const el = document.createElement("div")
    el.className = "dialog-box";
    document.body.appendChild(el);
    const app = new component().$mount(".dialog-box")
    app.$el.querySelector('.el-dialog').style.borderRadius = "10px";
    app.$el.querySelector('.el-dialog').style.margin = "auto";
    app.$el.querySelector('.el-dialog').style.top = "50%";
    app.$el.querySelector('.el-dialog').style.left = "50%";
    app.$el.querySelector('.el-dialog').style.transform = "translate(-50%,-50%)";
    app.$el.querySelector('.el-dialog').style.display = "inline-block";

    app.$el.querySelector('.el-dialog__headerbtn').style = "top:10px;right:15px;"
    app.$el.querySelector('.el-dialog__header').style = "padding:9px 10px 10px 20px;background-color:#dfdfe1;border-top-left-radius: 10px;border-top-right-radius: 10px;"
    app.$el.querySelector('.el-dialog__title').style = "font-size:14px;"
    if(app.$el.querySelector('.el-dialog__footer')){
        app.$el.querySelector('.el-dialog__footer').style.padding = "10px 20px"
        app.$el.querySelector('.el-dialog__footer').style.backgroundColor = "#f5f7fa"
        app.$el.querySelector('.el-dialog__footer').style.borderBottomLeftRadius = "10px";
        app.$el.querySelector('.el-dialog__footer').style.borderBottomRightRadius = "10px";
    }

  return app;
}

