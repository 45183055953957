<template>
	<div class="page-box">
		<el-form :model="form" ref="form" :rules="rules" label-width="80px" :inline="false" size="default">
			<el-form-item label="姓名:" prop="name" required>
				<el-input  :disabled="form.id?true:false" v-model="form.name" maxlength="10"></el-input>
			</el-form-item>
			<el-form-item label="账号:" prop="phone" required>
				<el-input   :disabled="form.id?true:false" v-model="form.phone" maxlength="11"></el-input>
			</el-form-item>
			<el-divider direction="horizontal"></el-divider>
			<el-form-item label="登录权限:" prop="mobileLoginAuthFlag">
				<el-checkbox v-model="form.mobileLoginAuthFlag" true-label="1" false-label="0">允许移动端登录</el-checkbox>
				<div class="tips">账号开通即默认允许PC端登录</div>
			</el-form-item>
			<el-form-item label="功能权限:">
				<div class="fn">
					<el-checkbox v-model="form.matchBackSetAuthFlag" true-label="1" false-label="0">后台设置</el-checkbox>
					<el-checkbox v-model="form.onlineAuthFlag" true-label="1" false-label="0">在线报名表</el-checkbox>
					<el-checkbox v-model="form.formAuthFlag" true-label="1" false-label="0">正式报名表</el-checkbox>
					<el-checkbox v-model="form.arrangeAuthFlag" true-label="1" false-label="0">赛事安排表</el-checkbox>
					<el-checkbox v-model="form.summaryAuthFlag" true-label="1" false-label="0">赛程总表</el-checkbox>
					<el-checkbox v-model="form.detailAuthFlag" true-label="1" false-label="0">选手赛程表</el-checkbox>
					<el-checkbox v-model="form.scoreAuthFlag" true-label="1" false-label="0">成绩表</el-checkbox>
					<el-checkbox v-model="form.teamManageAuthFlag" true-label="1" false-label="0">代表队管理</el-checkbox>
					<el-checkbox v-model="form.groupsettingAuthFlag" true-label="1" false-label="0">组别设项</el-checkbox>
				</div>
			</el-form-item>
		</el-form>

	</div>
</template>
<script>
	import {
		handleAddManager,
		handleQueryManagersDetails
	} from '@index/api/mymatchs/matchinfo'
	import {
		Message
	} from 'element-ui'
	export default {
		name: "add-manager",
		data() {
			return {
				form: {
					id: '',
					name: '',
					phone: '',
					matchId: sessionStorage.getItem("currMatchId"),
					mobileLoginAuthFlag: '0',
					onlineAuthFlag: '0',
					formAuthFlag: '0',
					arrangeAuthFlag: '0',
					summaryAuthFlag: '0',
					detailAuthFlag: '0',
					scoreAuthFlag: '0',
					teamManageAuthFlag: '0',
					groupsettingAuthFlag: '0',
				},
				rules: {
					name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}, ],
					phone: [{
						required: true,
						message: '请输入账号',
						trigger: 'blur'
					}, ],
				}
			}
		},
		created() {
			if (this.$attrs.params && this.$attrs.params.id) {
				this.$data.form.id = this.$attrs.params.id;
				this.queryManagersDetails(this.$data.form.id);
			}			
		},
		mounted() {

		},
		beforeDestroy() {

		},
		methods: {
			confirm(callback, close) {
				var _this = this;
				this.$refs.form.validate((valid) => {
					if (valid) {
						_this.addManager(() => {
							callback();
							close();
						});

					} else {
						return false;
					}
				})
			},
			queryManagersDetails(id) {
				handleQueryManagersDetails(id).then(res => {				
					this.$data.form = res.data;
				})
			},
			addManager(callback) {
				handleAddManager(this.$data.form).then(res => {
					console.log(">handleAddManager>>" + JSON.stringify(res));
					if (res.status == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						});
						callback();
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
	.page-box {
		width: 650px;
		height: 450px;
		overflow-y: auto;
		padding: 15px;

		.tips {
			font-size: 13px;
			color: #D8001B;
			line-height: 15px;
		}

		.fn {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-auto-rows: auto;
		}
	}
</style>